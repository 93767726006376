import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';

import { LoadingWrapper, LoadingSpinner } from './wrappers';

export const Loading = ({ isActive, scrollLock, size, ...props }) => {
  useEffect(() => {
    if (scrollLock) {
      if (isActive) {
        document.body.classList.add('scroll-lock');
      } else {
        document.body.classList.remove('scroll-lock');
      }
    }

    return () => {
      document.body.classList.remove('scroll-lock');
    };
  }, [isActive, scrollLock]);

  return (
    <AnimatePresence>
      {!!isActive && (
        <LoadingWrapper
          role="alert"
          aria-busy="true"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          {...props}
        >
          <LoadingSpinner role="presentation" {...{ size }}>
            <div aria-hidden="true" />
            <div aria-hidden="true" />
            <div aria-hidden="true" />
            <div aria-hidden="true" />
            <div aria-hidden="true" />
            <div aria-hidden="true" />
            <div aria-hidden="true" />
            <div aria-hidden="true" />
          </LoadingSpinner>
        </LoadingWrapper>
      )}
    </AnimatePresence>
  );
};

Loading.defaultProps = {
  isActive: true,
  position: 'fixed',
  scrollLock: true,
  size: 120,
};

Loading.propTypes = {
  isActive: PropTypes.bool,
  position: PropTypes.oneOf(['absolute', 'fixed']),
  scrollLock: PropTypes.bool,
  size: PropTypes.number,
};
