/* eslint-disable */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { API_BASE, requestMessages } from 'shared/utils/request';
import mm from 'shared/utils/middleMan';
import { actions } from './slice';
import { selectUserId } from './selectors';

export function* getUserDetails(token) {
  try {
    const { data: userDetail } = yield call(axios.request, {
      method: 'GET',
      url: `${API_BASE}/api/auth/me`,
      headers: { Authorization: `Bearer ${token}` },
    });

    // Store token in session for future visits
    yield call(mm.storagePut, 'token', token, 'session');

    // Signal success
    yield put(actions.signInSuccess({ user: userDetail, authToken: token }));

    yield call(
      mm.storagePut,
      'hasRecentApplication',
      userDetail.hasRecentApplication,
    );
  } catch ({
    response: {
      status,
      data: { title },
    },
  }) {
    // Token error
    yield call(mm.clearStorage, { key: 'token' });
    yield put(
      actions.signInError(
        [401, 403].includes(status)
          ? 'Your session has expired. Please sign in again to continue.'
          : title || requestMessages.errorGeneric,
      ),
    );
  }
}

export function* signInSaga({ payload: { username, password, jobId } }) {
  try {
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    if (jobId) formData.append('jobId', jobId);

    const response = yield call(axios.request, {
      method: 'POST',
      url: `${API_BASE}/api/auth/login`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    // Try acquiring token after login
    yield call(getUserDetails, response.data.access_token);
  } catch ({
    response: {
      data: { title },
    },
  }) {
    yield put(actions.signInError(title || requestMessages.errorGeneric));
  }
}

export function* checkUserSaga() {
  try {
    const { location, value: token } = yield call(mm.checkAllStorage, {
      key: 'token',
    });

    if (token) {
      yield call(getUserDetails, token, location === 'local');
    } else {
      yield put(actions.signInError());
    }
  } catch {
    yield put(actions.signInError());
  }
}

export function* signUpSaga({ payload }) {
  try {
    const response = yield call(axios.request, {
      method: 'POST',
      url: `${API_BASE}/api/auth/create`,
      data: payload,
      headers: { 'Content-Type': 'application/json' },
    });

    // Try acquiring token after login
    yield call(getUserDetails, response.data.access_token, false);
  } catch ({
    response: {
      data: { title },
    },
  }) {
    yield put(actions.signUpError(title || requestMessages.errorGeneric));
  }
}

export function* updateUserSaga({ payload: { payload, resolve, reject } }) {
  const userId = yield select(selectUserId);
  const { value: token } = yield call(mm.checkAllStorage, {
    key: 'token',
  });

  try {
    const response = yield call(axios.request, {
      method: 'POST',
      url: `${API_BASE}/api/profile/candidate/save`,
      data: {
        id: userId,
        ...payload,
        preferredCommunication: payload.preferredCommunication?.toString(),
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    // Signal success
    yield put(actions.updateUserSuccess(response.data));
    if (resolve) resolve(response.data);
  } catch ({
    response: {
      data: { title },
    },
  }) {
    const error = title || requestMessages.errorGeneric;

    // Signal error
    yield put(actions.updateUserError(error));
    if (reject) reject(error);
  }
}

export function* signOutSaga({ payload: { timedOut = false } }) {
  yield call(mm.deleteAll, {});
  yield put(actions.signOutSuccess({ timedOut }));
}

export function* userSaga() {
  yield all([
    takeLatest(actions.signIn.type, signInSaga),
    takeLatest(actions.checkUser.type, checkUserSaga),
    takeLatest(actions.signUp.type, signUpSaga),
    takeLatest(actions.signOut.type, signOutSaga),
    takeLatest(actions.updateUser.type, updateUserSaga),
  ]);
}
