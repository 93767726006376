import React from 'react';
import PropTypes from 'prop-types';

import { Typo } from 'components/atoms/Typo';
import { ButtonAlpha } from 'components/atoms/Button';
import { BaseModal } from '../BaseModal';
import { ContentWrapper, FooterWrapper } from './wrappers';

export const ConfirmModal = ({
  cancelLabel,
  confirmLabel,
  content,
  isOpen,
  onCancel,
  onConfirm,
  ...props
}) => {
  const onCancelClick = () => {
    onCancel();
  };

  const onConfirmClick = () => {
    onConfirm();
  };

  return (
    <BaseModal
      closeButtonLabel="Cancel"
      maxWidth="656px"
      onClose={onCancel}
      {...{ isOpen, ...props }}
    >
      {!!content && (
        <ContentWrapper>
          {typeof content === 'string' ? (
            <Typo variant="text-md" label={content} />
          ) : (
            content
          )}
        </ContentWrapper>
      )}

      <FooterWrapper>
        <ButtonAlpha
          onClick={onCancelClick}
          label={cancelLabel}
          variant="ghost"
        />
        <ButtonAlpha onClick={onConfirmClick} label={confirmLabel} />
      </FooterWrapper>
    </BaseModal>
  );
};

ConfirmModal.defaultProps = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  content: '',
  maxWidth: '600px',
};

ConfirmModal.propTypes = {
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
