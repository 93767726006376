import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: ${p => p.theme.font.sans};
    color: ${p => p.theme.color.black900};

    &.scroll-lock {
      overflow: hidden;
    }
  }

  /* https://a11yproject.com/posts/how-to-hide-content/ */
  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
  }

  /* Base Modal Open/Close Transitions */
  .BaseModal {
    &.ReactModal__Content {
      opacity: 0.85;
      transform: translateY(16px);
      transition: all 150ms ease-in-out;
    }

    &.ReactModal__Content--after-open {
      opacity: 1;
      transform: translateY(0);
    }

    &.ReactModal__Content--before-close {
      opacity: 0.85;
      transform: translateY(16px);
    }
  }
`;
