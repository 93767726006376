const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

/* const makeRandomId
 * int length: the desired length of the random id
 * returns string result: the random id output, with a minimum length of 5 characters and no maximum length
 */
export const makeRandomId = length => {
  let result = characters.charAt(Math.floor(Math.random() * 52));
  const charactersLength = characters.length;
  for (let i = 0; i < (!length || length < 4 ? 4 : length - 1); i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};
