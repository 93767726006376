import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as AddCircle } from './assets/add-circle.svg';
import { ReactComponent as ArrowLeft } from './assets/arrow-left.svg';
import { ReactComponent as CaretDown } from './assets/caret-down.svg';
import { ReactComponent as Close } from './assets/close.svg';
import { ReactComponent as Info } from './assets/info.svg';
import { ReactComponent as Search } from './assets/search.svg';
import { ReactComponent as Check } from './assets/check.svg';
import { ReactComponent as Dash } from './assets/dash.svg';
import { ReactComponent as Ellipse } from './assets/ellipse.svg';
import { ReactComponent as EyeOpen } from './assets/eye-open.svg';
import { ReactComponent as EyeClosed } from './assets/eye-closed.svg';
import { ReactComponent as Menu } from './assets/menu.svg';
import { ReactComponent as Trash } from './assets/trash.svg';
import { ReactComponent as Valid } from './assets/valid.svg';
import { ReactComponent as Invalid } from './assets/invalid.svg';
import { ReactComponent as Placeholder } from './assets/placeholder.svg';
import { ReactComponent as Warning } from './assets/warning.svg';
import { ReactComponent as Question } from './assets/question.svg';
import { ReactComponent as Location } from './assets/location.svg';
import { ReactComponent as Suitcase } from './assets/suitcase.svg';
import { ReactComponent as Dollars } from './assets/dollars.svg';
import { ReactComponent as Gift } from './assets/gift.svg';
import { ReactComponent as Hospital } from './assets/hospital.svg';
import { ReactComponent as CheckCircle } from './assets/check-circle.svg';

export const Icon = ({ variant }) => {
  switch (variant) {
    case 'add-circle':
      return <AddCircle />;
    case 'arrow-left':
      return <ArrowLeft />;
    case 'close':
      return <Close />;
    case 'caret-down':
      return <CaretDown />;
    case 'info':
      return <Info />;
    case 'placeholder':
      return <Placeholder />;
    case 'search':
      return <Search />;
    case 'trash':
      return <Trash />;
    case 'check':
      return <Check />;
    case 'dash':
      return <Dash />;
    case 'ellipse':
      return <Ellipse />;
    case 'eye-open':
      return <EyeOpen />;
    case 'eye-closed':
      return <EyeClosed />;
    case 'menu':
      return <Menu />;
    case 'valid':
      return <Valid />;
    case 'invalid':
      return <Invalid />;
    case 'warning':
      return <Warning />;
    case 'question':
      return <Question />;
    case 'location':
      return <Location />;
    case 'suitcase':
      return <Suitcase />;
    case 'dollars':
      return <Dollars />;
    case 'gift':
      return <Gift />;
    case 'hospital':
      return <Hospital />;
    case 'check-circle':
      return <CheckCircle />;
    default:
      return null;
  }
};

Icon.defaultProps = {
  variant: 'placeholder',
};

Icon.propTypes = {
  variant: PropTypes.oneOf([
    'add-circle',
    'trash',
    'arrow-left',
    'close',
    'caret-down',
    'info',
    'search',
    'check',
    'dash',
    'ellipse',
    'eye-open',
    'eye-closed',
    'menu',
    'valid',
    'invalid',
    'placeholder',
    'warning',
    'question',
    'location',
    'suitcase',
    'dollars',
    'gift',
    'hospital',
    'check-circle',
  ]),
};
