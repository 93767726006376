const vowels = ['a', 'e', 'i', 'o', 'u'];

export const a = val => {
  if (val) {
    return vowels.includes(val.charAt(0).toLowerCase())
      ? `an ${val}`
      : `a ${val}`;
  }

  return null;
};
