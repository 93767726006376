export const size = {
  tablet: '768px',
  desktop: '1024px',
  full: '1440px',
};

export const device = {
  tablet: `(min-width: ${size.tablet})`,
  desktop: `(min-width: ${size.desktop})`,
  full: `(min-width: ${size.full})`,
};
