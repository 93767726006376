import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ReactModal from 'react-modal';
import { ThemeProvider } from 'styled-components';
import { App } from 'containers/App';
import configureStore from 'data/store';
import { GlobalStyle } from 'shared/globalStyle';
import themePrimary from 'shared/themePrimary';

const store = configureStore();
const container = document.getElementById('root');
const root = createRoot(container);

// Tell react-modal where our app lives
// http://reactcommunity.org/react-modal/accessibility/
ReactModal.setAppElement(container);

root.render(
  <Provider store={store}>
    <HelmetProvider>
      <BrowserRouter>
        <ThemeProvider theme={themePrimary}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
          <GlobalStyle />
        </ThemeProvider>
      </BrowserRouter>
    </HelmetProvider>
  </Provider>,
);
