import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserAuthenticated,
  selectUserSignedOut,
  selectUserLoginPath,
  selectUserLoading,
} from 'data/user/selectors';
import { actions as userActions } from 'data/user/slice';
import { Loading } from 'components/atoms/Loading';
import { SessionTimeoutModal } from 'components/molecules/Modal';

const ProtectedRoute = ({ children, redirectPath }) => {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const userAuthenticated = useSelector(selectUserAuthenticated);
  const userSignedOut = useSelector(selectUserSignedOut);
  const userLoginPath = useSelector(selectUserLoginPath);
  const userLoading = useSelector(selectUserLoading);

  useEffect(() => {
    if (!userAuthenticated) {
      dispatch(userActions.checkUser());
    }
  }, [dispatch, userAuthenticated]);

  useEffect(() => {
    if (
      !userLoading &&
      !userAuthenticated &&
      !userLoginPath &&
      !userSignedOut &&
      pathname !== '/'
    ) {
      dispatch(userActions.setLoginPath(`${pathname}${search}`));
    }
  }, [userAuthenticated, userLoading, userSignedOut]); /* eslint-disable-line */

  if (!userLoading && !userAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  if (!userLoading && !!userAuthenticated) {
    return (
      <>
        {children}
        <SessionTimeoutModal />
      </>
    );
  }

  return <Loading />;
};

ProtectedRoute.defaultProps = {
  redirectPath: '/',
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
  redirectPath: PropTypes.string,
};

export default ProtectedRoute;
