import { createSelector } from '@reduxjs/toolkit';

import { initialState, sliceKey } from './slice';

export const selectDomain = state => state[sliceKey] || initialState;

export const selectUser = createSelector([selectDomain], state => state.user);

export const selectUserDetail = createSelector(
  [selectDomain, (state, detail) => detail],
  (state, detail) => state.user[detail],
);

export const selectUserDetails = createSelector(
  [selectDomain, (state, details) => details],
  (state, details) =>
    details.reduce((prev, curr) => ({ ...prev, [curr]: state.user[curr] }), {}),
);

export const selectUserId = createSelector(
  [selectDomain],
  state => state.user.id,
);

export const selectUserAuthToken = createSelector(
  [selectDomain],
  state => state.authToken,
);

export const selectUserAuthenticated = createSelector(
  [selectDomain],
  state => state.authenticated,
);

export const selectUserLoading = createSelector(
  [selectDomain],
  state => state.loading,
);

export const selectUserLoaded = createSelector(
  [selectDomain],
  state => state.loaded,
);

export const selectUserLoadError = createSelector(
  [selectDomain],
  state => state.loadError,
);

export const selectUserUpdating = createSelector(
  [selectDomain],
  state => state.updating,
);

export const selectUserUpdateError = createSelector(
  [selectDomain],
  state => state.updateError,
);

export const selectUserSignedOut = createSelector(
  [selectDomain],
  state => state.signedOut,
);

export const selectUserTimedOut = createSelector(
  [selectDomain],
  state => state.timedOut,
);

export const selectUserLoginPath = createSelector(
  [selectDomain],
  state => state.loginPath,
);

export const selectUserSessionJobId = createSelector(
  [selectDomain],
  state => state.sessionJobId,
);
