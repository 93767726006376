import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  user: {},
  authenticated: false,
  authToken: '',
  loaded: false,
  loading: true,
  loadError: null,
  signedOut: false,
  timedOut: false,
  loginPath: null,
  updating: false,
  updateError: null,
  sessionJobId: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // auth user (login)
    signIn(state) {
      state.user = {};
      state.authToken = '';
      state.authenticated = false;
      state.loaded = false;
      state.loading = true;
      state.loadError = null;
    },
    signInSuccess(state, action) {
      state.user = action.payload.user;
      state.authToken = action.payload.authToken;
      state.authenticated = true;
      state.loaded = true;
      state.loading = false;
      state.signedOut = false;
      state.timedOut = false;
    },
    signInError(state, action) {
      state.loading = false;
      state.loadError = action.payload || null;
    },

    // check user (verifying token)
    checkUser(state) {
      state.user = {};
      state.authToken = '';
      state.authenticated = false;
      state.loading = true;
      state.loadError = null;
    },

    // new user sign up
    signUp(state) {
      state.user = {};
      state.authToken = '';
      state.authenticated = false;
      state.loaded = false;
      state.loading = true;
      state.loadError = null;
    },
    signUpError(state, action) {
      state.loading = false;
      state.loadError = action.payload || null;
    },

    // sign out
    signOut(state) {
      state.userLoading = true;
    },
    signOutSuccess: (state, action) => ({
      ...initialState,
      signedOut: true,
      timedOut: action.payload.timedOut,
    }),

    // update user details
    updateUser(state) {
      state.updating = true;
      state.updateError = null;
    },
    updateUserSuccess(state, action) {
      state.user = action.payload;
      state.updating = false;
    },
    updateUserError(state, action) {
      state.updating = false;
      state.updateError = action.payload || null;
    },

    // session job id setting
    setSessionJobId(state, action) {
      state.sessionJobId = action.payload;
    },

    // auth bouncing
    setLoginPath(state, action) {
      state.loginPath = action.payload;
    },

    // reset store
    resetStore: () => ({
      ...initialState,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = userSlice;
