/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  lookups: {},
  loading: false,
  loadError: null,
};

const lookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  reducers: {
    // get lookups
    getLookups(state) {
      state.loading = true;
      state.loadError = null;
    },
    getLookupsSuccess(state, action) {
      state.lookups = {
        ...state.lookups,
        ...action.payload,
      };
      state.loading = false;
    },
    getLookupsError(state, action) {
      state.loading = false;
      state.loadError = action.payload || null;
    },

    // reset store
    resetStore: () => ({
      ...initialState,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = lookupsSlice;
