import { all, call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';

import { API_BASE, requestMessages } from 'shared/utils/request';
import mm from 'shared/utils/middleMan';
import { actions } from './slice';

export function* getLookupsSaga({ payload: lookups }) {
  try {
    const { value: token } = yield call(mm.checkAllStorage, {
      key: 'token',
    });

    const queryString = lookups.reduce(
      (prev, curr) => `${prev}${prev !== '?' ? '&' : ''}lookup=${curr}`,
      '?',
    );

    const { data: response } = yield call(axios.request, {
      method: 'GET',
      url: `${API_BASE}/api/profile/candidate/lookups${queryString}`,
      headers: { Authorization: `Bearer ${token}` },
    });

    yield put(actions.getLookupsSuccess(response));
  } catch ({
    response: {
      data: { title },
    },
  }) {
    yield put(actions.getLookupsError(title || requestMessages.errorGeneric));
  }
}

export function* lookupsSaga() {
  yield all([takeLatest(actions.getLookups.type, getLookupsSaga)]);
}
