import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper } from './wrappers';

export const Typo = ({ label, ...props }) => (
  <Wrapper {...props}>{label}</Wrapper>
);

Typo.defaultProps = {
  as: 'div',
  fontColor: '',
  variant: 'text-md',
  weight: 'normal',
};

Typo.propTypes = {
  as: PropTypes.oneOf([
    'div',
    'h1',
    'h2',
    'h3',
    'h4',
    'p',
    'span',
    'strong',
    'em',
  ]),
  fontColor: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  variant: PropTypes.oneOf([
    'eyebrow',
    'h1',
    'h2',
    'h3',
    'h4',
    'text-md',
    'text-lg',
    'text-sm',
    'text-xs',
  ]),
  weight: PropTypes.oneOf(['normal', 'medium', 'bold']),
};
