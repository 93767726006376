import { device, size } from './devices';

const theme = {
  /* Colors */
  color: {
    /* Primary Colors */
    field100: '#E8F4D9',
    field200: '#D1E8B2',
    field300: '#BBDD8C',
    field400: '#A4D165',
    field500: '#8DC63F',
    field600: '#719E32',
    field700: '#557726',
    field800: '#384F19',
    field900: '#1C280D',
    ansel100: '#E6F0F3',
    ansel200: '#CEE1E7',
    ansel300: '#84B3C4',
    ansel400: '#5295AC',
    ansel500: '#086788',
    ansel600: '#06526D',
    ansel700: '#053E52',
    ansel800: '#032936',
    ansel900: '#02151B',
    space100: '#C6E2E3',
    space200: '#8DC4C6',
    space300: '#55A7AA',
    space400: '#386F71',
    space500: '#1C3738',
    space600: '#162C2D',
    space700: '#112122',
    space800: '#0B1616',
    space900: '#060B0B',

    /* Secondary Colors */
    maize100: '#FEF5DC',
    maize200: '#FDEBB9',
    maize300: '#FCE097',
    maize400: '#FBD674',
    maize500: '#FACC51',
    maize600: '#F8B911',
    maize700: '#C18E06',
    maize800: '#815F04',
    maize900: '#402F02',
    grapefruit100: '#FFDFDE',
    grapefruit200: '#FFBFBD',
    grapefruit300: '#FF9E9D',
    grapefruit400: '#FF7E7C',
    grapefruit500: '#FF5E5B',
    grapefruit600: '#CC4B49',
    grapefruit700: '#993837',
    grapefruit800: '#662624',
    grapefruit900: '#331312',

    /* Neutral Colors */
    black100: '#F4F4F4',
    black200: '#D1D2D3',
    black300: '#BBBCBD',
    black400: '#A4A5A7',
    black500: '#8D8F91',
    black600: '#717274',
    black700: '#555657',
    black800: '#38393A',
    black900: '#1C1D1D',
    white100: '#FFFFFF',
  },

  /* Box Shadows */
  shadow: {
    sm: '0px 1px 3px rgba(32, 32, 34, 0.1), 0px 1px 2px rgba(32, 32, 34, 0.06)',
    md: '0px 4px 8px -2px rgba(32, 32, 34, 0.1), 0px 2px 4px -2px rgba(32, 32, 34, 0.06)',
    lg: '0px 12px 16px -4px rgba(32, 32, 34, 0.1), 0px 4px 6px -2px rgba(32, 32, 34, 0.05)',
    xl: '0px 20px 24px -4px rgba(32, 32, 34, 0.1), 0px 8px 8px -4px rgba(32, 32, 34, 0.04)',
    xxl: '0px 24px 48px -12px rgba(32, 32, 34, 0.25)',
    xxxl: '0px 32px 64px -12px rgba(32, 32, 34, 0.2)',
  },

  /* Font Families */
  font: {
    sans: "'Montserrat', sans-serif",
    cursive: "'Abril Fatface', cursive",
  },

  /* Sizes & Devices */
  size,
  device,
};

export default theme;
