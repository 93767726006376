const clearStorage = ({ key }) =>
  new Promise(resolve => {
    window.localStorage.removeItem(key);
    window.sessionStorage.removeItem(key);
    resolve('success');
  });

const deleteAll = () =>
  new Promise(resolve => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    resolve('success');
  });

const checkAllStorage = ({ key }) =>
  new Promise((resolve, reject) => {
    storageGet(key, window.localStorage).then(localResult => {
      if (!localResult) {
        storageGet(key, window.sessionStorage).then(sessionResult => {
          if (!sessionResult) {
            reject(false); // eslint-disable-line
          } else {
            resolve({
              location: 'session',
              value: sessionResult,
            });
          }
        });
      } else {
        resolve({
          location: 'local',
          value: localResult,
        });
      }
    });
  });

const storageGet = (key, storage) =>
  new Promise(resolve => {
    let data = storage.getItem(key) ? storage.getItem(key) : false;
    if (data !== 'undefined') {
      data = JSON.parse(data);
    } else {
      data = false;
    }
    resolve(data);
  });

const storagePut = (key, data, storage) => {
  const realStorage =
    storage === 'local' ? window.localStorage : window.sessionStorage;
  return new Promise(resolve => {
    realStorage.setItem(key, JSON.stringify(data));
    resolve(data);
  });
};

const exports = {
  clearStorage,
  checkAllStorage,
  deleteAll,
  storageGet,
  storagePut,
};

export default exports;
