import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions as userActions } from 'data/user/slice';
import { ConfirmModal } from '../ConfirmModal';
import { addEventListeners, removeEventListeners } from './utils';

export const SessionTimeoutModal = () => {
  const dispatch = useDispatch();
  const [isWarningModalOpen, setWarningModalOpen] = useState(false);

  useEffect(() => {
    const createTimeout1 = () =>
      setTimeout(() => {
        setWarningModalOpen(true);
      }, 1200000); // 20 minutes (1000*60*20=1200000)

    const createTimeout2 = () =>
      setTimeout(() => {
        dispatch(userActions.signOut({ timedOut: true }));
      }, 60000); // 1 minute (1000*60=60000)

    const listener = () => {
      if (!isWarningModalOpen) {
        clearTimeout(timeout);
        timeout = createTimeout1();
      }
    };

    // Initialization
    let timeout = isWarningModalOpen ? createTimeout2() : createTimeout1();
    addEventListeners(listener);

    // Cleanup
    return () => {
      removeEventListeners(listener);
      clearTimeout(timeout);
    };
  }, [dispatch, isWarningModalOpen]);

  return (
    <ConfirmModal
      title="Your session is about to timeout"
      content="For your security, we log you out after a period of inactivity."
      cancelLabel="Sign out"
      confirmLabel="Keep working"
      isOpen={isWarningModalOpen}
      onConfirm={() => setWarningModalOpen(false)}
      onCancel={() => dispatch(userActions.signOut({}))}
      onCloseButtonClick={() => setWarningModalOpen(false)}
      onRequestClose={() => setWarningModalOpen(false)}
      hideCloseButtonLabel
    />
  );
};
