import styled, { keyframes } from 'styled-components/macro';
import { motion } from 'framer-motion';

const load1 = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

export const LoadingWrapper = styled(motion.div)`
  position: ${p => p.position || 'fixed'};
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${p => p.theme.color.white100}CC; /* 80% */
`;

export const LoadingSpinner = styled.div`
  color: ${p => p.theme.color.space500};
  display: inline-block;
  position: relative;
  width: ${p => p.size}px;
  height: ${p => p.size}px;

  div {
    transform-origin: ${p => p.size * 0.5}px ${p => p.size * 0.5}px;
    animation: ${load1} 1.25s linear infinite;

    &::after {
      content: ' ';
      display: block;
      position: absolute;
      top: 0;
      left: ${p => p.size * 0.4625}px;
      width: ${p => p.size * 0.075}px;
      height: ${p => p.size * 0.3}px;
      border-radius: 20%;
      background: ${p => p.theme.color.space500};
    }

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1.05s;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -0.9s;
    }

    &:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -0.75s;
    }

    &:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -0.6s;
    }

    &:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -0.45s;
    }

    &:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -0.3s;
    }

    &:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -0.15s;
    }

    &:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: 0s;
    }
  }
`;
