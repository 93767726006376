import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 32px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

export const TitleWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`;

export const CloseWrapper = styled.div`
  flex-shrink: 0;
`;
