import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import themePrimary from 'shared/themePrimary';
import { ButtonAlpha } from 'components/atoms/Button';
import { Typo } from 'components/atoms/Typo';
import { Wrapper, HeaderWrapper, TitleWrapper, CloseWrapper } from './wrappers';

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `${themePrimary.color.space500}80`,
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'relative',
    margin: '0',
    border: `none`,
    borderRadius: '24px',
    background: 'white',
    boxShadow: themePrimary.shadow.xxxl,
    outline: 'none',
    overflow: 'hidden',
    padding: 0,
    width: '90%',
    maxWidth: '724px',
    maxHeight: '95%',
    display: 'flex',
  },
};

export const BaseModal = ({
  closeButtonLabel,
  children,
  height,
  hideCloseButton,
  hideCloseButtonLabel,
  isOpen,
  maxWidth,
  minHeight,
  onClose,
  onCloseButtonClick,
  onOpen,
  title,
  width,
  ...props
}) => {
  let newModalStyles = modalStyles;
  if (width) {
    newModalStyles = {
      ...modalStyles,
      content: {
        ...newModalStyles.content,
        width,
      },
    };
  }

  if (maxWidth) {
    newModalStyles = {
      ...modalStyles,
      content: {
        ...newModalStyles.content,
        maxWidth,
      },
    };
  }

  if (height) {
    newModalStyles = {
      ...modalStyles,
      content: {
        ...newModalStyles.content,
        height,
      },
    };
  }

  if (minHeight) {
    newModalStyles = {
      ...modalStyles,
      content: {
        ...newModalStyles.content,
        minHeight,
      },
    };
  }

  return (
    <Modal
      className="BaseModal"
      closeTimeoutMS={150}
      onAfterOpen={onOpen}
      onRequestClose={onClose}
      style={newModalStyles}
      {...{ isOpen }}
      {...props}
    >
      <Wrapper>
        <HeaderWrapper>
          <TitleWrapper>
            {!!title &&
              (typeof title === 'string' ? (
                <Typo weight="bold" variant="text-md" label={title} />
              ) : (
                title
              ))}
          </TitleWrapper>

          {!hideCloseButton && (
            <CloseWrapper>
              <ButtonAlpha
                iconRight="close"
                label={closeButtonLabel}
                size="xs"
                variant="link"
                onClick={onCloseButtonClick || onClose}
                hideLabel={hideCloseButtonLabel}
              />
            </CloseWrapper>
          )}
        </HeaderWrapper>

        {children}
      </Wrapper>
    </Modal>
  );
};

BaseModal.defaultProps = {
  closeButtonLabel: 'Close',
  height: '',
  hideCloseButton: false,
  hideCloseButtonLabel: false,
  maxWidth: '',
  minHeight: '',
  onCloseButtonClick: undefined,
  onOpen: undefined,
  title: '',
  width: '',
};

BaseModal.propTypes = {
  closeButtonLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  height: PropTypes.string,
  hideCloseButton: PropTypes.bool,
  hideCloseButtonLabel: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  maxWidth: PropTypes.string,
  minHeight: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func,
  onOpen: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  width: PropTypes.string,
};
