import styled from 'styled-components';

export const ContentWrapper = styled.div`
  margin: 24px 0;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;
