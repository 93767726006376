import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'components/atoms/Icon';
import { Wrapper } from './wrappers';

export const ButtonAlpha = ({
  hideLabel,
  iconLeft,
  iconRight,
  label,
  ...props
}) => (
  <Wrapper aria-label={label} {...{ hideLabel }} {...props}>
    {iconLeft !== 'none' && <Icon role="presentation" variant={iconLeft} />}
    {!hideLabel && <span>{label}</span>}
    {iconRight !== 'none' && <Icon role="presentation" variant={iconRight} />}
  </Wrapper>
);

ButtonAlpha.defaultProps = {
  align: 'center',
  block: false,
  disabled: false,
  hideLabel: false,
  iconLeft: 'none',
  iconRight: 'none',
  size: 'md',
  type: 'button',
  variant: 'primary',
};

ButtonAlpha.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  variant: PropTypes.oneOf([
    'primary',
    'ghost',
    'ghost-light',
    'text',
    'text-light',
    'link',
    'link-light',
  ]),
};
