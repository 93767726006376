/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Routes, Route } from 'react-router-dom';
import { DateTime } from 'luxon';

import { MAINT_END, MAINT_START } from 'shared/utils/maint';
import DashboardPage from 'containers/DashboardPage/Loadable';
import HomePage from 'containers/HomePage/Loadable';
import MaintenancePage from 'containers/MaintenancePage/Loadable';
import NewPasswordPage from 'containers/NewPasswordPage/Loadable';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import OnboardingPage from 'containers/OnboardingPage/Loadable';
import WaitingRoomPage from 'containers/WaitingRoomPage/Loadable';
import WelcomePage from 'containers/WelcomePage/Loadable';
import RecentApplicationPage from 'containers/RecentApplicationPage/Loadable';

import ProtectedRoute from './ProtectedRoute';

const checkIsMaint = (maintStart, maintEnd) => {
  const current = DateTime.local().toUTC();
  const start = DateTime.fromISO(maintStart, { zone: 'utc' });
  const end = DateTime.fromISO(maintEnd, { zone: 'utc' });
  return start.isValid && start <= current && (!end.isValid || end >= current);
};

export const App = () => {
  const isMaint = checkIsMaint(MAINT_START, MAINT_END);

  return (
    <>
      <Helmet
        titleTemplate="%s - MAS Medical Staffing"
        defaultTitle="MAS Medical Staffing"
        htmlAttributes={{ lang: 'en-US' }}
      >
        <meta
          name="description"
          content="Now hiring healthcare professionals nationwide!"
        />
      </Helmet>

      {isMaint ? (
        <MaintenancePage />
      ) : (
        <Routes>
          <Route index element={<HomePage />} />
          <Route path="welcome/*" element={<WelcomePage />} />
          <Route
            path="waitingRoom"
            element={
              <ProtectedRoute>
                <WaitingRoomPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="onboarding"
            element={
              <ProtectedRoute>
                <OnboardingPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="recentapplication"
            element={
              <ProtectedRoute>
                <RecentApplicationPage />
              </ProtectedRoute>
            }
          />
          <Route path="newPassword/:token" element={<NewPasswordPage />} />
          <Route path="404" element={<NotFoundPage />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      )}
    </>
  );
};
