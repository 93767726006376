import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  flyout: '',
  isDirty: false,
  step: 0,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    // Step Progression
    setStep(state, action) {
      state.isDirty = false;
      state.flyout = '';
      state.step = action.payload;
    },

    // Dirty Status
    setIsDirty(state, action) {
      state.isDirty = action.payload;
    },

    // Flyout Toggling
    openFlyout(state, action) {
      state.flyout = action.payload;
    },
    closeFlyout(state) {
      state.flyout = '';
    },

    // reset store
    resetStore: () => ({
      ...initialState,
    }),
  },
});

export const { actions, reducer, name: sliceKey } = onboardingSlice;
